import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import {
    AspectRatio,
    Flex,
    Text,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import React from 'react'
import {styleConfigChakraKeys} from '../../constants'

import Link from '../link'

export const BlogArticleCard = ({data, dynamicImageProps}) => {
    const styles = useMultiStyleConfig(
        styleConfigChakraKeys.productScrollerTile
    )
    const image = data?.cardImage
    const {text, ...cta} = data.cardCta
    return (
        <Link
            {...cta}
            buttonStyles={{
                ...styles.link,
                position: 'relative',
                color: 'black',
                padding: '0px'
            }}
        >
            <Flex
                position="absolute"
                bottom={{base: '10px', lg: '21px'}}
                left={{base: '10px', lg: '26px'}}
                zIndex="1"
                direction="column"
                gap={{base: '10px', lg: '12px'}}
            >
                <Text
                    fontFamily="Raleway"
                    fontWeight="300"
                    fontSize={{
                        base: '16px',
                        md: '18px',
                        lg: '20px',
                        xl: '24px'
                    }}
                    maxW={{
                        base: '158px',
                        md: '140px',
                        lg: '150px',
                        xl: '240px'
                    }}
                    lineHeight={{base: '18px', lg: '24px', xl: '28px'}}
                >
                    {data?.cardTitle}
                </Text>
                <Link
                    buttonStyles={{
                        ...styles.link,
                        color: 'black',
                        border: '1px solid black',
                        textAlign: 'center',
                        maxWidth: {base: '108px', md: '120px', lg: '100%'},
                        fontSize: '14px',
                        minWidth: 'auto'
                    }}
                    {...data?.cardCta}
                />
            </Flex>
            {image && (
                <AspectRatio {...styles.image} ratio={1 / 1.1}>
                    <DynamicImage
                        src={image.desktop.url}
                        widths={dynamicImageProps?.widths}
                        imageProps={{
                            alt: image.desktop.title,
                            width: '100%',
                            height: '100%',
                            ...dynamicImageProps?.imageProps
                        }}
                    />
                </AspectRatio>
            )}
        </Link>
    )
}
