import {
    Box,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'
import {styleConfigChakraKeys} from '../../constants'

const SliderProgressChip = ({
    index,
    moveByChip,
    handleKeyDown,
    variant = 'flat',
    selected = false,
    chipColor = ''
}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.sliderProgress, {
        variant,
        selected,
        chipColor
    })
    return (
        <Box
            key={index}
            {...styles.chip}
            selected
            onKeyDown={(event) => handleKeyDown(event, index)}
            tabIndex={0} // Make the div focusable
            onClick={() => moveByChip(index)}
            role="button"
        />
    )
}

SliderProgressChip.displayName = 'SliderProgressChip'

SliderProgressChip.propTypes = {
    index: PropTypes.number,
    moveByChip: PropTypes.func,
    handleKeyDown: PropTypes.func,
    variant: PropTypes.oneOf(['circle', 'flat']),
    selected: PropTypes.bool,
    chipColor: PropTypes.string
}

export default SliderProgressChip
