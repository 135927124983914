import PropTypes from 'prop-types'
import React from 'react'

import {
    Flex,
    HStack,
    Text,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {styleConfigChakraKeys} from '../../constants'
import Link from '../link'
import ScrollerNavigationButtons from './scroller-navigation-buttons'

const ProductScrollerHeader = ({
    isLoading,
    products,
    scroll,
    viewAll,
    title,
    showScrollerNav = false
}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.productScroller)
    return (
        <Flex
            justifyContent="space-between"
            pt={{base: '20px', lg: '0'}}
            alignItems="flex-end"
            marginBottom="20px"
        >
            <Text
                fontWeight={{base: '300', lg: '400'}}
                fontSize={['20px', '23px', '32px']}
                fontFamily="Raleway"
                lineHeight={['23px', '23px', '39px']}
            >
                {title}
            </Text>
            {showScrollerNav && (
                <HStack gap={{lg: '51px'}}>
                    {!isLoading && products?.length > 3 && (
                        <ScrollerNavigationButtons scroll={scroll} />
                    )}
                    <Link buttonStyles={styles.viewAllButton} {...viewAll} />
                </HStack>
            )}
        </Flex>
    )
}

ProductScrollerHeader.displayName = 'ProductScrollerHeader'

ProductScrollerHeader.propTypes = {
    title: PropTypes.any,
    products: PropTypes.array,
    isLoading: PropTypes.bool,
    showScrollerNav: PropTypes.bool,
    scroll: PropTypes.func,
    viewAll: PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
        openLineInNewTab: PropTypes.bool,
        theme: PropTypes.object,
        type: PropTypes.string,
        isExternal: PropTypes.bool,
        externalUrl: PropTypes.string
    })
}

export default ProductScrollerHeader
