import {useBreakpointValue} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useEffect, useRef, useState} from 'react'

export const useProductScroller = ({
    items,
    itemWidth,
    defaultNumberOfItems = {base: 2, md: 3, lg: 4},
    scrollContainerId
}) => {
    const scrollRef = useRef()

    const [scrollPosition, setScrollPosition] = useState(0)
    const numberOfItems = useBreakpointValue(defaultNumberOfItems, {
        fallback: 3
    })
    const totalScrolls = Math.ceil(items?.length / numberOfItems)

    useEffect(() => {
        const container = document.getElementById(scrollContainerId)
        const handleScroll = () => {
            const itemWidth = container.firstChild.offsetWidth
            const currentScroll = Math.round(container.scrollLeft / itemWidth)
            setScrollPosition(Math.ceil(currentScroll / numberOfItems))
        }

        if (container) {
            container.addEventListener('scroll', handleScroll)
            return () => container.removeEventListener('scroll', handleScroll)
        }
    }, [itemWidth, numberOfItems])

    // Scroll the container left or right by 100%. Passing no args or `1`
    // scrolls to the right, and passing `-1` scrolls left.
    const scroll = (direction = 1) => {
        scrollRef.current?.scrollBy({
            top: 0,
            left: direction * scrollRef.current.clientWidth,
            behavior: 'smooth'
        })
    }

    // move using scrollIndicatorChip on click on one of the chips
    const moveByChip = (index) => {
        scrollRef.current?.scrollTo({
            left: index * scrollRef.current.clientWidth,
            behavior: 'smooth'
        })
    }

    return {scroll, scrollRef, totalScrolls, scrollPosition, moveByChip}
}
