import PropTypes from 'prop-types'
import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'

import {
    Box,
    HStack,
    IconButton
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {LeftChevronIcon, RightChevronIcon} from '../icons'

const ScrollerNavigationButtons = ({scroll}) => {
    const intl = useIntl()

    const buttons = useMemo(() => [
        {
            icon: <LeftChevronIcon width="7px" height="14px" />,
            onClick: () => scroll(-1),
            id: 'left'
        },
        {
            icon: <RightChevronIcon width="7px" height="14px" />,
            onClick: () => scroll(1),
            id: 'right'
        }
    ])
    return (
        <HStack gap="39px">
            {buttons.map((button) => (
                <Box
                    display={{
                        base: 'none',
                        lg: 'block'
                    }}
                    key={button.id}
                >
                    <IconButton
                        minWidth="7px"
                        data-testid="product-scroller-nav-left"
                        aria-label={intl.formatMessage({
                            id: 'product_scroller.assistive_msg.scroll_left',
                            defaultMessage: 'Scroll products left'
                        })}
                        height="fit-content"
                        color="black"
                        icon={button.icon}
                        borderRadius="full"
                        colorScheme="whiteAlpha"
                        onClick={() => {
                            return button.onClick()
                        }}
                    />
                </Box>
            ))}
        </HStack>
    )
}

ScrollerNavigationButtons.displayName = 'ScrollerNavigationButtons'

ScrollerNavigationButtons.propTypes = {
    scroll: PropTypes.func
}

export default ScrollerNavigationButtons
